<template lang='pug'>
div.form__wrapper
  router-link(to='/')
    img.logo(src='../assets/logo.png')
    h1 Create Account
  el-form(v-if='!submitted', :model='form', :rules='rules', ref='createAccountForm', 
          v-loading='loading', @keyup.enter.native='createAccount')
    div.double-form-item
      el-form-item(prop='first_name')
        el-input(v-model='form.first_name', placeholder='First name', autofocus)
      el-form-item(prop='last_name')
        el-input(v-model='form.last_name', placeholder='Last name')
    el-form-item(prop='email')
      el-input(v-model='form.email', placeholder='Email')
    el-form-item(prop='phone_number')
      el-input(v-model='form.phone_number', placeholder='Phone number')
    div.spacer
    div.double-form-item
      el-form-item(prop='password')
        el-input(v-model='form.password', type='password', placeholder='Password')
      el-form-item(prop='password_confirmation')
        el-input(v-model='form.password_confirmation', type='password', placeholder='Confirm')
    el-form-item
      el-button.submit(type='primary', @click='createAccount') Submit

  div(v-else)
    p.thanks Thanks for signing up! We just need to verify your email address and then you'll be all set to log in. Check your 
      span.bold {{ form.email.substring(form.email.lastIndexOf('@')) }} 
      | email address for an email from us and follow the instructions provided.

    p.support Sometimes spam filters don't like us. If you don't see a verification email, try checking your spam folder. Otherwise, please feel free to contact customer support.

    router-link(to='/') 
      el-button(type='text') Back to login
</template>

<script>
import api from '../api'
import Cookies from 'js-cookie'

export default {
  name: 'CreateAccount',
  data () {
    let checkPasswordsMatch = (rule, value, callback) => {
      // check that password confirmation matches password
      if (this.form.password && this.form.password.length > 0 && value !== this.form.password) {
        callback(new Error('Passwords don\'t match'))
      } else {
        callback()
      }
    }

    let checkValidPhoneNumber = (rule, value, callback) => {
      // strip everything but numbers from phone and check length
      if (value && value.length > 0 && value.replace(/[^\/\d]/g,'').length < 10) {
        callback(new Error('Enter a valid phone number'))
      } else {
        callback()
      }
    }

    let checkEmailAvailable = (rule, value, callback) => {
      // check that email belongs to a valid domain and has not been taken
      if (value && value.length > 0) {
        api.get('validate', { email: value }).then((res) => {
          let error = res.data.error

          if (error && error === 'account_exists') {
            callback(new Error('There is already an account with this email'))
          } else if (error && error === 'invalid_domain') {
            callback(new Error('Please use your company email'))
          } else {
            callback()
          }
        })
      }
    }

    return {
      loading: false,
      submitted: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        password: null,
        password_confirmation: null
      },
      rules: {
        first_name: [
          { required: true, message: 'Enter a first name', trigger: 'submit' }
        ],
        last_name: [
          { required: true, message: 'Enter a last name', trigger: 'submit' }
        ],
        email: [
          { required: true, message: 'Enter an email address', trigger: 'submit' },
          { type: 'email', message: 'Enter a valid email address', trigger: 'blur' },
          { validator: checkEmailAvailable, trigger: 'blur' }
        ],
        phone_number: [
          { required: true, message: 'Enter a phone number', trigger: 'submit' },
          { validator: checkValidPhoneNumber, trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Enter a password', trigger: 'submit' },
          { min: 14, message: 'Use 14 or more characters', trigger: 'blur' }
        ],
        password_confirmation: [
          { validator: checkPasswordsMatch, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    createAccount () {
      this.$refs.createAccountForm.validate((valid) => {
        if (valid) {
          this.loading = true
          api.post('create', this.form).then((res) => {
            this.loading = false
            this.submitted = true
          }).catch((error) => {
            this.loading = false
            this.$message.error('Something has gone wrong. Contact proton.ai if this problem persists.')
          })
        }
      })
    }
  },
  mounted () {
    this.form.email = this.$route.query.email

    const company = this.$route.query.company

    if (company) {
      Cookies.set('company', company, { path: '', domain: '.proton.ai' })
    }
  }
}
</script>

<style lang='scss'>
@import '../scss/common';
</style>